import React from "react";
import { Link } from "gatsby";
import { Highlight, Snippet } from "react-instantsearch-dom";
import "../styles/OnsiteSearch.css";


export function OnSiteSearchHit({ hit }) {
  const { slug, tags } = hit;
  // const { id, slug, tags, body } = hit;

  //   const dateFormatter = new Intl.DateTimeFormat();

  return (
    <div className="OnSiteSearchHit__root">
      <Link to={`/${slug}`}>
        <div className="OnSiteSearchHit__headline">
          <strong>
            <Highlight hit={hit} attribute="title" />
          </strong>
        </div>
        <div className="OnSiteSearchHit__description">
          <Snippet hit={hit} attribute='body' separator='-' />

        </div>
        <div className="OnSiteSearchHit__meta">
          <div className="OnSiteSearchHit__meta__tags">
            {tags ? (
              <>
                {tags.map((tag) => (
                  <span key={tag} className="OnSiteSearchHit__meta__tags__tag">
                    {tag}
                  </span>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </Link>
    </div>
  );
}
