import React from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Configure } from "react-instantsearch-dom";
import { OnSiteSearchHitList } from "./OnSiteSearchHitList";
import "instantsearch.css/themes/reset.css";
import "instantsearch.css/themes/algolia.css";

const searchClient = algoliasearch(
  // process.env.REACT_APP_ALGOLIA_APP_ID,
  // process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY
  "Z6PDHE9Z0L",
  "97240ad590e5e4e46da55bd9999b57b4"
);

export function OnSiteSearch() {
  console.log(process.env);
  return (
    <InstantSearch
      // indexName={process.env.REACT_APP_ALGOLIA_INDEX_NAME}
      indexName="gatsby-blog"
      searchClient={searchClient}
    >
      <Configure
        // https://www.algolia.com/doc/api-reference/search-api-parameters/
        hitsPerPage={20}
        removeStopWords
        analytics
        analyticsTags={["on-site-search"]}
        attributesToSnippet={['body:30']}
      />
      <SearchBox showLoadingIndicator submit={null} reset={null} />
      <OnSiteSearchHitList />
    </InstantSearch>
  );
}
