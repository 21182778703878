/** @jsx jsx */
import React from "react";
import { jsx, Link as TLink } from "theme-ui";
import useMinimalBlogConfig from "../../../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src//hooks/use-minimal-blog-config";
import { OnSiteSearch } from "../../../components/OnSiteSearch";

const HeaderExternalLinks = () => {
  const { externalLinks } = useMinimalBlogConfig();

  return (
    <React.Fragment>
      {externalLinks && externalLinks.length > 0 && (
        <div>
          {/* <div
            sx={{ "a:not(:first-of-type)": { ml: 3 }, fontSize: [1, `18px`] }}
          >
            {externalLinks.map((link) => (
              <TLink key={link.url} href={link.url}>
                {link.name}
              </TLink>
            ))}
          </div> */}
          <OnSiteSearch />
        </div>
      )}
    </React.Fragment>
  );
};

export default HeaderExternalLinks;
